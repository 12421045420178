<template>
  <section class="hero is-fullheight">
    <nav class="guest-nav">
      <nuxt-link :to="localePath('/')" class="logo">
        <Logo />
      </nuxt-link>
    </nav>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered is-mobile">
          <div
            class="column is-centered is-11-mobile is-7-tablet is-5-widescreen is-5-fullhd"
          >
            <!-- Child  -->
            <nuxt />

            <!-- Back Button -->
            <div class="form-row form-row--2">
              <div class="hg-control">
                <HgButton color="" tag="nuxt-link" :to="localePath('/')">
                  <HgIcon name="home" pack="fas" />
                  <span>
                    {{ $t('backToHome') }}
                  </span>
                </HgButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    Logo: () => import('@/components/partials/Logo')
  },
  head() {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: this.$config.ROBOTS },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: this.$i18n.t('holidayguru')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: ['de', 'at'].includes(this.$i18n.locale)
            ? '/assets/images/og/ug.jpg'
            : '/assets/images/og/hg.jpg'
        }
      ]
    };
  }
}
</script>

<style scoped lang="scss">
.hero {
  background-color: $teal-80;
  min-height: 100vh;
}

.hero-body {
  z-index: 10;
  position: relative;
  padding: 4rem 2rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.columns.is-centered {
  display: flex;
}

.column.is-centered {
  @media screen and (min-width: 768px) {
    min-width: 550px;
  }
  max-width: 550px;
  min-width: 320px;
  margin: 0 auto;
  width: 80%;
}

.guest-nav {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 1.5rem;
}

.logo {
  height: 4rem;
  width: 12rem;
}
</style>

<style>
.hero .box {
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 0.15);
}
</style>
