import Vue from 'vue';

import { config, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Regular
import {
  faStar,
  faSuitcase,
  faPlane,
  faInfoCircle,
  faMapMarkerAlt,
  faCamera,
  faEnvelope,
  faLongArrowRight,
  faThumbsUp,
  faCheck,
  faCopy,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faBars,
  faHomeAlt,
  faExclamationTriangle,
  faCheckCircle,
  faBuilding,
  faTimesCircle,
  faSearch as farSearch,
  faTimes as farTimes,
  faClock,
  faSparkles,
  faShip
} from '@fortawesome/pro-regular-svg-icons';

// Light
import {
  faPhone,
  faUser,
  faShare,
  faHeart,
  faTimes,
  faPlus,
  faMinus,
  faEnvelope as falEnvelope,
  faBars as falBars
} from '@fortawesome/pro-light-svg-icons';

// Solid
import {
  faHome,
  faPhoneAlt,
  faSearch,
  faTh,
  faShare as fasShare,
  faHeart as fasHeart,
  faStar as fasStar,
  faStarHalf as fasStarHalf,
  faBus
} from '@fortawesome/free-solid-svg-icons';

// Brand
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faTiktok,
  faPinterest,
  faWhatsapp,
  faFacebookMessenger,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

// Font Awesome
library.add(
  // FAR
  faBars,
  faStar,
  faSuitcase,
  faPlane,
  faCopy,
  faInfoCircle,
  faMapMarkerAlt,
  faCamera,
  faThumbsUp,
  faLongArrowRight,
  faCheck,
  faBars,
  faHomeAlt,
  faTimesCircle,
  farSearch,
  faEnvelope,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  farTimes,
  faExclamationTriangle,
  faCheckCircle,
  faBuilding,
  faClock,
  faSparkles,
  faShip,
  // FAL
  faPlus,
  faPhone,
  faShare,
  faUser,
  faHeart,
  faTimes,
  faMinus,
  falEnvelope,
  falBars,
  // FAS
  faHome,
  faSearch,
  faTh,
  fasShare,
  fasHeart,
  faPhoneAlt,
  fasStar,
  fasStarHalf,
  faBus,
  // FAB
  faFacebook,
  faInstagram,
  faYoutube,
  faTiktok,
  faPinterest,
  faWhatsapp,
  faFacebookMessenger,
  faTwitter
);

config.autoAddCss = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);
