export default ctx => {
  (function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga',
  );
  const { locale } = ctx.app.i18n;
  let gaId = '';

  if (locale === 'de') {
    gaId = 'UA-33637388-24';
  } else if (locale === 'at') {
    gaId = 'UA-41786134-11';
  } else if (locale === 'ch') {
    gaId = 'UA-43584907-12';
  } else if (locale === 'nl') {
    gaId = 'UA-47489761-9';
  } else if (locale === 'es') {
    gaId = 'UA-48904199-9';
  }

  window.ga('create', gaId, 'auto');

  // send the first page hit after you are assigned the experiment
  setTimeout(function() {
    window.ga('send', 'pageview');
  }, 1000);
};
