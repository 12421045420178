const localeDomains = require('~~/lang/localeDomains');

export const state = () => ({
  isMobile: true,
  currentScreenSize: 0,
  imageNumber: Math.floor(Math.random() * 10),
  isAppView: false,
  hasTrackingInfo: false,
  isSearchRoute: true,
  triggerSearch: false,
  searchTerm: '',
  tags: [],
  initialLoadComplete: false,
  showAuthModal: false,
  showShareModal: false,
  selectedDeal: null,
  mainContentLoaded: true,
  currentSort: { label: 'Neueste', value: 'DATE_DESC' },
  isGridView: true,
  localeDomains,
  currentPosition: [],
  experiments: []
});

export const mutations = {
  triggerSearch(state, { searchTerm, tags }) {
    state.searchTerm = searchTerm;
    state.tags = tags;
    state.triggerSearch = !state.triggerSearch;
  },
  resetSearch(state) {
    state.searchTerm = '';
    state.tags = [];
  },
  setInitialLoadComplete(state, bool) {
    state.initialLoadComplete = bool;
  },
  setIsGridView(state, bool) {
    state.isGridView = bool;
  },
  setCurrentSort(state, sort) {
    state.currentSort = sort;
  },
  setShowAuthModal(state, bool) {
    state.showAuthModal = bool;
  },
  setSelectedDeal(state, deal) {
    state.selectedDeal = deal;
  },
  setShowShareModal(state, bool) {
    state.showShareModal = bool;
  },
  setContentLoaded(state, bool) {
    state.mainContentLoaded = bool;
  },
  setIsAppView(state, bool) {
    state.isAppView = bool;
  },
  setIsSearchRoute(state, bool) {
    state.isSearchRoute = bool;
  },
  setIsMobile(state, bool) {
    state.isMobile = bool;
  },
  setIsImageNumber(state, number) {
    state.imageNumber = number;
  },
  setCurrentScreenSize(state, size) {
    state.currentScreenSize = size;
  },
  setHasTrackingInfo(state, bool) {
    state.hasTrackingInfo = bool;
  },
  setCurrentPosition(state, { path, position }) {
    if (state.currentPosition.length < 4) {
      // add new Path to array, if array is not full
      state.currentPosition.push({ path, position: 0 });
    } else {
      // remove first element of array and add new path
      state.currentPosition.shift();
      state.currentPosition.push({ path, position: 0 });
    }
    if (state.currentPosition.length >= 2) {
      // set position of previous path to previous position (previous path is second last element in array, because we just added a new path)
      state.currentPosition[
        state.currentPosition.length - 2
      ].position = position;
    }
  },
  setExperiments(state, feature) {
    state.experiments.push(feature);
  },
  setKameleoonEngineCode(state, code) {
    state.kameleoonEngineCode = code;
  }
};

export const actions = {
  async nuxtServerInit({ dispatch, commit }, context) {
    const apolloHelpers = context.app.$apolloHelpers;
    const token = apolloHelpers.getToken();

    if (token) {
      // Check login token
      try {
        await dispatch('auth/getUser');
      } catch (e) {
        apolloHelpers.onLogout();
        await dispatch('auth/logout');
      }
    } else {
      apolloHelpers.onLogout();
      await dispatch('auth/logout');
    }
  },

  nuxtClientInit({ commit, dispatch }, context) {
    const prefersGrid = localStorage.getItem('prefersGridView');
    const hasAcceptedCookies = localStorage.getItem('hasAcceptedCookies');

    if (prefersGrid === 'true' || prefersGrid === null) {
      commit('localStorage/setGridPreference', true);
    } else {
      commit('localStorage/setGridPreference', false);
    }

    // dispatch('tracking/init', context.route);

    commit('setIsGridView', prefersGrid);
    commit('localStorage/setHasAcceptedCookies', hasAcceptedCookies);
  }
};

export const getters = {
  getLocaleDomains(state) {
    return state.localeDomains;
  }
};
